import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CandidateData } from '../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { findCandidateWithExtId } from '../helpers/candidates';
import FillArea from './fill-area';
import { Button, Checkbox } from 'semantic-ui-react';
import ErrorMessage from './error-message';

const ConsultantProfile = () => {
  let params = useParams();
  const myRole = localStorage.getItem('skills-my-role');
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState('off');
  const [candidateInfo, setCandidateInfo] =
    useState<CandidateData | null>(null);
  // Selectors
  const personsData: CandidateData[] = useSelector(
    (state: {
      userdata: { usersData: { profiles: CandidateData[] } };
    }) => {
      return state.userdata.usersData.profiles;
    }
  );

  // Manage cached userdata loading
  useEffect(() => {
    dispatch({
      type: 'search/setErrorMessage',
      payload: null,
    });
    const cachedUserdata = localStorage.getItem('skills-personData');
    if (personsData.length === 0 && cachedUserdata) {
      dispatch({
        type: 'userdata/initBatchOfProfiles',
        payload: JSON.parse(cachedUserdata),
      });
    }
    return () => {
      dispatch({
        type: 'search/setErrorMessage',
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    console.log('params', params);
    const data = findCandidateWithExtId(params.id, personsData);
    console.log('Consultant data is ', data);
    setCandidateInfo(data);
  }, [params, personsData]);

  function handleClick() {
    setEditMode((prevState) => {
      return prevState === 'on' ? 'off' : 'on';
    });
  }

  return (
    <div className="consultant-wrapper">
      <h1 className="consultant-title">Consultant Profile</h1>
      {candidateInfo && candidateInfo.profileInfo && (
        <div className="consultant-info">
          <a
            rel="noreferrer"
            href={`https://pdi-pepron.zendesk.com/agent/users/${candidateInfo.profileInfo?.id}`}
            target="_blank"
            title={`Click here to open user in Zendesk`}
          >
            <h5>{candidateInfo.profileInfo?.name || 'N/A'}</h5>
          </a>
          <img
            src={candidateInfo.profileInfo?.photo?.toString()}
            height="100"
          />
          <p>
            <strong>ID: </strong>
            {candidateInfo.profileInfo?.id || 'N/A'}
          </p>
          <p>
            <strong>Role: </strong>
            {candidateInfo.profileInfo?.role || 'N/A'}
          </p>
          <p>
            <strong>Available: </strong>
            {' ' + candidateInfo.profileInfo?.available || 'N/A'}
          </p>
          <p>
            <strong>Postcode: </strong>
            {candidateInfo.profileInfo?.postcode || 'N/A'}
          </p>
          <p>
            <strong>Pool: </strong>
            {candidateInfo.profileInfo?.pool || 'N/A'}
          </p>
          <p>
            <strong>Access: </strong>
            {candidateInfo.access || 'N/A'}
          </p>
        </div>
      )}
      {myRole === 'agent' && candidateInfo?.access === 'edit' && (
        <div className="edit-button">
          <label>
            <strong>Edit mode {editMode} </strong>
          </label>
          <Checkbox
            toggle
            active={editMode === 'on'}
            onClick={handleClick}
          />
        </div>
      )}
      <ErrorMessage />
      {candidateInfo && candidateInfo.profile && (
        <FillArea
          editModeOn={editMode === 'on' ? 'edit-mode' : ''}
          onMapChange={() => {
            return;
          }}
          profileData={candidateInfo.profile}
          serverId={candidateInfo.serverId}
          shouldUpdateServer={myRole === 'agent' ? true : false}
        />
      )}
    </div>
  );
};

export default ConsultantProfile;
