import React from 'react';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';

const RedirectingSpinner = () => {
  return (
    <Dimmer active inverted>
      <Loader>Logging in</Loader>
    </Dimmer>
  );
};

export default RedirectingSpinner;
