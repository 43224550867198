import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { SkillValue } from '../interfaces';
import { useSelector } from 'react-redux';
import SkillButton from './skill-button';

interface SkillSelectionProps {
  id: string;
  initialValue: SkillValue;
  onSkillValueChange: (level: SkillValue) => void;
  editMode?: string;
}

const SkillSelection = ({
  id,
  initialValue,
  onSkillValueChange,
  editMode,
}: SkillSelectionProps) => {
  const [skillValue, setSkillValue] = useState(SkillValue.Empty);

  useEffect(() => {
    setSkillValue(initialValue);
    return () => {
      setSkillValue(SkillValue.Empty);
    };
  }, [initialValue]);

  return (
    <div className="skill-buttons">
      <SkillButton
        id={id}
        initialValue={skillValue}
        onChange={onSkillValueChange}
        title="No understanding"
        initialLevel={SkillValue.NoUnderstanding}
        onSetSkillValue={setSkillValue}
        editMode={editMode}
      ></SkillButton>
      <SkillButton
        id={id}
        initialValue={skillValue}
        onChange={onSkillValueChange}
        title="Beginner"
        initialLevel={SkillValue.Beginner}
        onSetSkillValue={setSkillValue}
        editMode={editMode}
      ></SkillButton>
      <SkillButton
        id={id}
        initialValue={skillValue}
        onChange={onSkillValueChange}
        title="Amateur"
        initialLevel={SkillValue.Amateur}
        onSetSkillValue={setSkillValue}
        editMode={editMode}
      ></SkillButton>
      <SkillButton
        id={id}
        initialValue={skillValue}
        onChange={onSkillValueChange}
        title="Professional"
        initialLevel={SkillValue.Professional}
        onSetSkillValue={setSkillValue}
        editMode={editMode}
      ></SkillButton>
      <SkillButton
        id={id}
        initialValue={skillValue}
        onChange={onSkillValueChange}
        title="Specialist"
        initialLevel={SkillValue.Specialist}
        onSetSkillValue={setSkillValue}
        editMode={editMode}
      ></SkillButton>
      <SkillButton
        id={id}
        initialValue={skillValue}
        onChange={onSkillValueChange}
        title="Guru"
        initialLevel={SkillValue.Guru}
        onSetSkillValue={setSkillValue}
        editMode={editMode}
      ></SkillButton>
    </div>
  );
};

export default SkillSelection;
