import * as server from '../api/skills-backend';

const getMyInitialSkills = async (serverId: string) => {
  console.log('getInitialSkillData started...');
  let response;
  try {
    response = await server.default.get(`profile/${serverId}/skill`);
  } catch (error) {
    console.error('skillDataHandlers.ts: ', error.message);
  }
  return response?.data;
};

const getDefaultSkillTree = async () => {
  console.log('getDefaultSkillTree started...');
  let response;
  try {
    response = await server.default.get('skill');
    return response.data;
  } catch (error) {
    console.error('skillDataHandlers.ts: ', error.message);
  }
  return null;
};

export { getMyInitialSkills, getDefaultSkillTree };
