import {
  ExternalType,
  Candidate,
  CandidateData,
  ProfileData,
  SkillValueData,
  SkillValue,
} from '../interfaces';

interface SearchState {
  availabilityFilter: string;
  candidates: Candidate[];
  fillLevelFilter: string;
  leadSearchScores: Candidate[];
  leadSearchSelectedPerson: CandidateData;
  leadSearchSelectedLead: CandidateData;
  leadSearchIsLoading: Boolean;
  leadEditScores: Candidate[];
  locationFilter: string;
  peopleSearch: { sequence: { totalPoints: number } };
  peopleSearchScores: Candidate[];
  peopleSearchSelectedLead: CandidateData;
  poolFilter: string;
  profilesPoolAmount: number;
  searchDefaults: ProfileData;
  skillLoader: [{ id: string; loadingState: string }];
  errorMessage: {};
}

const initialState: SearchState = {
  availabilityFilter: '',
  candidates: [],
  fillLevelFilter: '',
  leadSearchScores: [],
  leadSearchSelectedPerson: null,
  leadSearchSelectedLead: null,
  leadSearchIsLoading: true,
  leadEditScores: [],
  locationFilter: '',
  peopleSearch: { sequence: { totalPoints: -1 } },
  peopleSearchScores: [],
  peopleSearchSelectedLead: null,
  poolFilter: '',
  profilesPoolAmount: 0,
  searchDefaults: null,
  skillLoader: [
    {
      id: '',
      loadingState: '',
    },
  ],
  errorMessage: null,
};

/**
 * Server can return duplicate external ids, they are not allowed in frontend computations
 * @param objectArray Profile or Lead arrays must include Candidate base properties
 * @returns
 */
const removeDuplicates = (objectArray) => {
  console.log('Removing duplicates started...');
  if (!objectArray) return objectArray;
  if (objectArray.length === 0) {
    console.warn('removeDuplicates allows zero array!');
  }
  let keys = objectArray.map((obj) => obj.externalId);
  let filtered = objectArray.filter(
    ({ externalId }, i) => !keys.includes(externalId, i + 1)
  );
  return filtered;
};

const searchSlice = (state = initialState, action) => {
  switch (action.type) {
    // --- generic reducers
    case 'search/initDefaultSkills': {
      return {
        ...state,
        searchDefaults: {
          skillTree: action.payload,
          externalId: -1,
          externalType: 'lead',
        },
      };
    }
    case 'search/addCandidates': {
      let candidateDTO = action.payload?.map(
        ({ id, zendeskId, zendeskType, access }) => {
          return {
            serverId: id,
            externalId: zendeskId,
            externalType: zendeskType,
            access,
          };
        }
      );
      if (!candidateDTO) {
        candidateDTO = [];
      }
      const updatedCandidates = [
        ...state.candidates,
        ...candidateDTO,
      ];
      return {
        ...state,
        candidates: removeDuplicates(updatedCandidates),
        profilesPoolAmount: updatedCandidates.length,
      };
    }
    // --- consultant search reducers
    case 'search/initCandidateScores': {
      // candidates must be always set before using this reducer
      const updatedPersons = state.candidates
        .filter(
          (candidate) =>
            candidate.externalType === ExternalType.Person
        )
        .map((candidate) => {
          return { ...candidate, score: 0 };
        });
      return { ...state, candidates: updatedPersons };
    }
    case 'search/addScorePoints': {
      const { externalId, scoreAddition } = action.payload;
      const updatedCandidates = state.candidates.map((candidate) => {
        if (candidate.externalId === externalId) {
          return {
            ...candidate,
            score: candidate.score + scoreAddition,
          };
        }
        return candidate;
      });
      return { ...state, candidates: updatedCandidates };
    }
    case 'search/resetScores': {
      const updatedCandidates = state.candidates.map((candidate) => {
        return { ...candidate, score: 0 };
      });
      return { ...state, candidates: updatedCandidates };
    }
    case 'search/filteredCandidates': {
      const updatedCandidates = state.candidates.map((candidate) => {
        return { ...candidate, score: 0 };
      });
      return { ...state, candidates: updatedCandidates };
    }
    // --- lead edit reducers
    case 'search/initLeadEditScores': {
      const updatedPersonCandidates: Candidate[] = state.candidates
        .filter(
          (candidate) =>
            candidate.externalType === ExternalType.Person
        )
        .map((person) => {
          return { ...person, score: 0 };
        });
      return { ...state, leadEditScores: updatedPersonCandidates };
    }
    case 'search/addLeadEditScorePoints': {
      const { externalId, scoreAddition } = action.payload;
      const updatedPersonCandidates = state.leadEditScores.map(
        (person) => {
          if (person.externalId === externalId) {
            return {
              ...person,
              score: person.score + scoreAddition,
            };
          }
          return person;
        }
      );
      return {
        ...state,
        leadEditScores: updatedPersonCandidates,
      };
    }
    case 'search/resetLeadEditScores': {
      const updatedPersonCandidates = state.candidates
        .filter(
          (candidate) =>
            candidate.externalType === ExternalType.Person
        )
        .map((person) => {
          return { ...person, score: 0 };
        });
      return { ...state, leadEditScores: updatedPersonCandidates };
    }
    // --- people search reducers
    case 'search/initPeopleSearchScores': {
      const updatedPeople: Candidate[] = state.candidates
        .filter(
          (candidate) =>
            candidate.externalType === ExternalType.Person
        )
        .map((person) => {
          return { ...person, score: 0 };
        });
      return { ...state, peopleSearchScores: updatedPeople };
    }
    case 'search/peopleSearchSelectedLead': {
      return {
        ...state,
        peopleSearchSelectedLead: action.payload,
      };
    }
    case 'search/addPeopleSearchScorePoints': {
      const { externalId, scoreAddition } = action.payload;
      const updatedPersonCandidates = state.peopleSearchScores.map(
        (candidate) => {
          if (candidate.externalId === externalId) {
            return {
              ...candidate,
              score: candidate.score + scoreAddition,
            };
          }
          return candidate;
        }
      );
      return {
        ...state,
        peopleSearchScores: updatedPersonCandidates,
      };
    }
    case 'search/resetPeopleSearchScores': {
      const updatedCandidates = state.peopleSearchScores.map(
        (candidate) => {
          return { ...candidate, score: 0 };
        }
      );
      return { ...state, peopleSearchScores: updatedCandidates };
    }
    case 'search/peopleSearchSetTotalPoints': {
      return {
        ...state,
        peopleSearch: {
          ...state.peopleSearch,
          sequence: {
            ...state.peopleSearch.sequence,
            totalPoints: action.payload,
          },
        },
      };
    }
    // --- lead search reducers
    case 'search/leadSearchSelectedPerson': {
      return {
        ...state,
        leadSearchSelectedPerson: action.payload,
      };
    }
    case 'search/leadSearchSelectedLead': {
      return {
        ...state,
        leadSearchSelectedLead: action.payload,
      };
    }
    case 'search/setSearchLoader': {
      return {
        ...state,
        leadSearchIsLoading: action.payload,
      };
    }
    // --- filter panel reducers
    case 'search/setLocationFilter': {
      return {
        ...state,
        locationFilter: action.payload,
      };
    }
    case 'search/setAvailabilityFilter': {
      return {
        ...state,
        availabilityFilter: action.payload,
      };
    }
    case 'search/setPoolFilter': {
      return {
        ...state,
        poolFilter: action.payload,
      };
    }
    case 'search/setFillLevelFilter': {
      return {
        ...state,
        fillLevelFilter: action.payload,
      };
    }
    case 'search/setErrorMessage': {
      return {
        ...state,
        errorMessage: action.payload,
      };
    }
    case 'search/setSkillLoader': {
      if (!action.payload) {
        return {
          ...state,
          skillLoader: initialState.skillLoader,
        };
      }
      const { id, loadingState } = action.payload;
      if (!state.skillLoader.find((skill) => skill.id === id)) {
        return {
          ...state,
          skillLoader: state.skillLoader.concat([
            {
              id,
              loadingState,
            },
          ]),
        };
      } else {
        const updatedLoader = state.skillLoader.map((skill) => {
          if (skill.id === id) {
            return {
              ...skill,
              loadingState,
            };
          }
          return skill;
        });
        return {
          ...state,
          skillLoader: updatedLoader,
        };
      }
    }
  }
  return state;
};

export default searchSlice;
