import React from 'react';
import { Route, Navigate, PathRouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCookieValue } from '../helpers/storage';

interface RouteWithAuthenticationProps {
  allowedRoles: string[];
  children: React.ReactNode;
}

// same props API as Route EM210510 | additionally, has allowedRoles EM210531
const RouteWithAuthentication = ({
  allowedRoles,
  children,
}: RouteWithAuthenticationProps) => {
  const authCookie = getCookieValue('skills-auth-token');
  const myRole = localStorage.getItem('skills-my-role');

  console.log('Authenticating...', authCookie, myRole);
  if (authCookie && allowedRoles.includes(myRole)) {
    return children;
  } else {
    return (
      <Navigate
        to="/login"
        replace={true}
        state={{ from: location.pathname }}
      />
    );
  }
};

export default RouteWithAuthentication;
