export const getCookieValue = (cname: string) => {
  let name = cname + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const setCookie = (
  cookieName: string,
  cookieValue: string
): void => {
  document.cookie = `${cookieName}=${cookieValue};max-age=86400;SameSite=Strict;Secure;Path=/`;
};

export const deleteCookies = (cookieName: string): void => {
  document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
};
