import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SpinnerPreparing } from './spinner-preparing';
import { updateLoadedProfiles } from '../helpers/profiles';
import { getSearchCandidates } from '../handlers/searchDataHandlers';
import { getCookieValue } from '../helpers/storage';

const ProfileManager = () => {
  const allowedRoles = ['consultant', 'sales', 'agent'];
  const dispatch = useDispatch();
  const candidates = useSelector(({ search }) => {
    return search.candidates;
  });
  const candidatesPoolAmount = useSelector(({ search }) => {
    return search.profilesPoolAmount;
  });
  const personsLoadedAmount = useSelector(({ userdata }) => {
    return userdata.usersData.profiles.length;
  });
  const leadsLoadedAmount = useSelector(({ userdata }) => {
    return userdata.usersData.leads.length;
  });
  const authToken = getCookieValue('skills-auth-token');

  const loadingProfiles = useSelector(({ userdata }) => {
    return userdata.loadingProfiles;
  });
  const myRole = localStorage.getItem('skills-my-role');

  // Runs when user logs in
  useEffect(() => {
    if (
      authToken &&
      allowedRoles.includes(myRole) &&
      candidates.length === 0
    ) {
      getSearchCandidates(authToken).then((candidates) => {
        dispatch({
          type: 'search/addCandidates',
          payload: candidates,
        });
      });
    }
  }, [authToken]);

  // Runs only when reducer search/addCandidates is executed EM210601
  useEffect(() => {
    if (
      candidatesPoolAmount !==
      personsLoadedAmount + leadsLoadedAmount
    ) {
      console.info('Updating profiles data...');
      dispatch({
        type: 'userdata/setLoading',
        payload: 'loading',
      });
      if (
        localStorage.getItem('skills-leadData') === null ||
        localStorage.getItem('skills-personData') === null ||
        localStorage.getItem('skills-leadData').length < 4 ||
        localStorage.getItem('skills-personData').length < 4
      ) {
        updateLoadedProfiles(dispatch, candidates);
      } else {
        // Load preloaded data from Storage
        dispatch({
          type: 'userdata/addBatchOfLeads',
          payload: JSON.parse(
            localStorage.getItem('skills-leadData')
          ),
        });
        console.info('...updated leads from pre-loaded storage!');
        dispatch({
          type: 'userdata/addBatchOfProfiles',
          payload: JSON.parse(
            localStorage.getItem('skills-personData')
          ),
        });
        console.info('...updated persons from pre-loaded storage!');
      }
    }
  }, [candidatesPoolAmount]);

  return (
    <div className="profile-manager">
      <SpinnerPreparing
        visible={loadingProfiles === 'loading'}
        what={'data'}
      />
    </div>
  );
};

export default ProfileManager;
