import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from './filter';
import { Option } from '../interfaces';

interface FilterPanelProps {
  locationOptions: Option[];
  availabilityOptions: Option[];
  poolOptions: Option[];
}

const FilterPanel = ({
  locationOptions,
  availabilityOptions,
  poolOptions,
}: FilterPanelProps) => {
  const dispatch = useDispatch();
  const location = useSelector(({ search }) => search.locationFilter);
  const availability = useSelector(
    ({ search }) => search.availabilityFilter
  );
  const pool = useSelector(({ search }) => search.poolFilter);
  const fillLevel = useSelector(
    ({ search }) => search.fillLevelFilter
  );
  const fillLevelOptions: Option[] = [
    {
      key: 'full',
      value: 'full',
      text: 'Full profiles',
    },
    {
      key: 'partial',
      value: 'partial',
      text: 'Partial profiles',
    },
  ];
  const handleLocationChange = (value: string) => {
    dispatch({ type: 'search/setLocationFilter', payload: value });
  };

  const handleAvailabilityChange = (value: string) => {
    dispatch({
      type: 'search/setAvailabilityFilter',
      payload: value,
    });
  };

  const handlePoolChange = (value: string) => {
    dispatch({
      type: 'search/setPoolFilter',
      payload: value,
    });
  };

  const handleFillLevelChange = (value: string) => {
    dispatch({
      type: 'search/setFillLevelFilter',
      payload: value,
    });
  };

  return (
    <div className="filter-panel">
      <div className="filter">
        <Filter
          icon="globe"
          filterValue={location}
          onFilterChange={handleLocationChange}
          options={locationOptions}
          title="Location"
        />
      </div>

      <div className="filter">
        <Filter
          icon="calendar alternate outline"
          filterValue={availability}
          onFilterChange={handleAvailabilityChange}
          options={availabilityOptions}
          title="Availability"
        />
      </div>

      <div className="filter">
        <Filter
          icon="bullseye"
          filterValue={pool}
          onFilterChange={handlePoolChange}
          options={poolOptions}
          title="Pool"
        />
      </div>

      <div className="filter">
        <Filter
          icon="tasks"
          filterValue={fillLevel}
          onFilterChange={handleFillLevelChange}
          options={fillLevelOptions}
          title="Fill level"
        />
      </div>
    </div>
  );
};

export default FilterPanel;
