const initialState = {
  externalData: null,
  redirectAnimation: '',
  error: '',
};

const loginSlice = (state = initialState, action) => {
  switch (action.type) {
    case 'login/setRedirectAnimation':
      return { ...state, redirectAnimation: action.payload };
    case 'login/addProfileData':
      const { zendeskData } = action.payload;
      return {
        ...state,
        externalData: zendeskData,
        redirectAnimation: 'active',
      };
    case 'login/setErrorObj':
      return { ...state, error: JSON.stringify(action.payload) };
  }
  return state;
};

export default loginSlice;
