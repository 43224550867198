import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Message } from 'semantic-ui-react';

const ErrorMessage = () => {
  const dispatch = useDispatch();
  const errorMessage = useSelector(({ search }) => {
    return search.errorMessage;
  });

  function handleClose() {
    dispatch({
      type: 'search/setErrorMessage',
      payload: null,
    });
  }
  if (errorMessage && errorMessage.message) {
    return (
      <div className="error-message">
        <Message negative onDismiss={handleClose}>
          <h4>Updating skill failed!</h4>
          <p>Please, try again</p>
          <p>{errorMessage.message}</p>
        </Message>
      </div>
    );
  }
};

export default ErrorMessage;
