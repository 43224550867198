import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import LoginView from './login-view';
import LeadEdit from './components/lead-edit';
import LeadSearch from './components/lead-search';
import FindConsultant from './components/find-consultant';
import PeopleSearch from './components/people-search';
import SkillsView from './components/skills-view';
import ProfileView from './components/profile-view';
import ConsultantProfile from './components/consultant-profile';

import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Navigate,
} from 'react-router-dom';
import RouteWithAuthentication from './components/route-with-authentication';
import 'semantic-ui-css/semantic.min.css';
import './styles/styles.css';
import Layout from './components/layout';
import ErrorComponent from './components/error-boundary';
import LeadSearchColumns from './components/lead-search-columns';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route
        path="/"
        element={
          <Navigate
            to="skills"
            state={{ from: window.location.pathname }}
          />
        }
      />
      <Route
        path="login"
        element={<LoginView />}
        errorElement={<ErrorComponent />}
      />
      <Route
        path="skills"
        element={
          <RouteWithAuthentication
            allowedRoles={[
              'end-user',
              'consultant',
              'sales',
              'agent',
            ]}
          >
            <SkillsView />
          </RouteWithAuthentication>
        }
        errorElement={<ErrorComponent />}
      />
      <Route
        path="lead"
        element={
          <RouteWithAuthentication allowedRoles={['agent', 'sales']}>
            <LeadEdit />
          </RouteWithAuthentication>
        }
        errorElement={<ErrorComponent />}
      />
      <Route
        path="lead-search"
        element={
          <RouteWithAuthentication allowedRoles={['agent']}>
            <LeadSearch />
          </RouteWithAuthentication>
        }
        errorElement={<ErrorComponent />}
      >
        <Route
          path=":leadId"
          element={<LeadSearchColumns />}
          errorElement={<ErrorComponent />}
        />
      </Route>
      <Route
        path="people-search"
        element={
          <RouteWithAuthentication allowedRoles={['agent']}>
            <PeopleSearch />
          </RouteWithAuthentication>
        }
        errorElement={<ErrorComponent />}
      />
      <Route
        path="find-consultant"
        element={
          <RouteWithAuthentication
            allowedRoles={['consultant', 'agent', 'sales']}
          >
            <FindConsultant />
          </RouteWithAuthentication>
        }
        errorElement={<ErrorComponent />}
      />
      <Route
        path="profile"
        element={
          <RouteWithAuthentication
            allowedRoles={[
              'end-user',
              'consultant',
              'sales',
              'agent',
            ]}
          >
            <ProfileView />
          </RouteWithAuthentication>
        }
        errorElement={<ErrorComponent />}
      />
      <Route
        path="consultant/:id"
        element={
          <RouteWithAuthentication
            allowedRoles={['consultant', 'agent', 'sales']}
          >
            <ConsultantProfile />
          </RouteWithAuthentication>
        }
        errorElement={<ErrorComponent />}
      />
    </Route>
  )
);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
