import React, { useEffect } from 'react';
import { Map } from 'immutable';
import { Skill, ProfileData, SkillValueData } from '../interfaces';
import SkillCategory from './skill-category';
import { sortCategoriesByName } from '../helpers/skills';
import { getCookieValue } from '../helpers/storage';

interface FillAreaProps {
  onMapChange: (
    map: Map<string, Map<string, SkillValueData>>
  ) => void;
  profileData: ProfileData;
  serverId: string;
  shouldUpdateServer: Boolean;
  editModeOn?: string;
}

const FillArea = ({
  onMapChange,
  profileData,
  serverId,
  shouldUpdateServer,
  editModeOn,
}: FillAreaProps) => {
  const authToken = getCookieValue('skills-auth-token');

  // useEffect(() => {
  //   console.log('EDIT MODE', editModeOn);
  // }, [editModeOn]);

  return (
    <div className={`selection-area ${editModeOn}`}>
      <div className="categories">
        {sortCategoriesByName(profileData?.skillTree?.children).map(
          (skill: Skill, index: number) => (
            <SkillCategory
              authToken={authToken}
              key={index}
              onMapChange={onMapChange}
              serverId={serverId}
              profileData={{ ...profileData, skillTree: skill }}
              shouldUpdateServer={shouldUpdateServer}
              editMode={editModeOn}
            />
          )
        ) ?? 'Missing skill data from server.'}
      </div>
    </div>
  );
};

export default FillArea;
