import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LogoutButton from './logout-button';
import { Menu, MenuItem, Dropdown, Button } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import ProfileManager from './profile-manager';
import { updateLoadedProfiles } from '../helpers/profiles';
import { Candidate } from '../interfaces';

const Header = () => {
  const myProfileData = useSelector(({ login }) => {
    return login.externalData?.profile;
  });
  const candidates: Candidate[] = useSelector(({ search }) => {
    return search.candidates;
  });

  const myRole = localStorage.getItem('skills-my-role');
  const dispatch = useDispatch();
  const location = useLocation();
  const allowedRoles = [
    ['end-user', 'consultant', 'sales', 'agent'], // my skills
    ['agent'], // people search
    ['agent'], // lead search
    ['consultant', 'agent', 'sales'], // find consultant
    ['end-user', 'consultant', 'sales', 'agent'], // profile & bio
    ['sales', 'agent'], // more options
  ];

  const menu = (
    <Menu stackable>
      {allowedRoles[0].includes(myRole) ? (
        <MenuItem
          name="skills"
          active={location.pathname === '/skills'}
          content={<Link to="/skills">My Skills</Link>}
        />
      ) : null}

      {/* {allowedRoles[1].includes(myRole) ? (
        <MenuItem
          name="leads"
          active={location.pathname === '/people-search'}
          content={<Link to="/people-search">People Search</Link>}
        />
      ) : null} */}
      {allowedRoles[2].includes(myRole) ? (
        <MenuItem
          name="people"
          active={location.pathname === '/lead-search'}
          content={<Link to="/lead-search">Lead Search</Link>}
        />
      ) : null}
      {allowedRoles[3].includes(myRole) ? (
        <MenuItem
          name="skillsearch"
          active={location.pathname === '/find-consultant'}
          content={<Link to="/find-consultant">Find Consultant</Link>}
        />
      ) : null}
      {allowedRoles[4].includes(myRole) ? (
        <MenuItem
          name="profile"
          active={location.pathname === '/profile'}
          content={<Link to="/profile">Profile</Link>}
        />
      ) : null}
      {allowedRoles[5].includes(myRole) ? (
        <Dropdown item text="More">
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                console.log('Clicked update', candidates.length);
                updateLoadedProfiles(dispatch, candidates);
              }}
              icon="sync"
              text="Reload data"
            />
          </Dropdown.Menu>
        </Dropdown>
      ) : null}
    </Menu>
  );

  return (
    <div className="header">
      <div className="header-name-logo-line">
        <img
          className="header-pepron-logo"
          src={'../img/logo.png'}
          width="800"
          height="400"
        ></img>
      </div>
      <p id="header-name" className="header-name">
        {myProfileData?.name}
      </p>
      <div className="header-button-line">
        <a
          rel="noreferrer"
          href={
            'https://connect.pepron.com/hc/en-us/categories/29062113341329-Pepron-Skills'
          }
          target="_blank"
          title={`Need help?`}
        >
          <Button className="help-button" icon="help" />
        </a>
        <LogoutButton />
      </div>
      <div className="header-interactables">
        <div className="header-menu">{myRole ? menu : null}</div>
      </div>
      <div className="profile-manager-content">
        <ProfileManager />
      </div>
    </div>
  );
};

export default Header;
