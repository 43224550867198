import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as server from '../api/skills-backend';
import { deleteCookies, getCookieValue } from '../helpers/storage';

const LogoutButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const successMessage = 'You have been logged out successfully.';

  const authToken = getCookieValue('skills-auth-token');

  const terminateAuthToken = async () => {
    try {
      const response = await server.default.instance.post('logout', {
        headers: {
          Authorization: authToken ? `Bearer ${authToken}` : '',
        },
      });
      const status = response.status;
      console.log('LOG OUT RES', response.status);
      dispatch({ type: 'logout/serverStatus', payload: status });
      switch (status) {
        case 200: {
          dispatch({
            type: 'logout/success',
            payload: successMessage,
          });
          break;
        }
        default:
          dispatch({
            type: 'logout/success',
            payload: `You have been logged out with server status ${status}`,
          });
      }
      return response;
    } catch (error) {
      console.error('Failed to terminate auth token', error.message);
      dispatch({
        type: 'logout/setErrorObj',
        payload: error,
      });
      return error;
    }
  };

  const signOutUser = () => {
    terminateAuthToken().then((response) => {
      console.log('THEN', response);
      deleteCookies('skills-auth-token');
      localStorage.clear();
      try {
        dispatch({ type: 'LOG_OUT' }); // This is the action that resets the state
        console.log('Logging out. Redirecting...');
      } catch (error) {
        console.error('Error in logging out', error);
      } finally {
        navigate('/login', { state: { from: location.pathname } });
      }
    });
  };

  return (
    <button
      className="logout-button"
      disabled={authToken ? false : true}
      onClick={signOutUser}
    >
      Logout
    </button>
  );
};

export default LogoutButton;
