import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CandidateInfo,
  ExternalType,
  ProfileData,
} from '../interfaces';
import FillArea from './fill-area';
import Instructions from './instructions';
import { getMyInitialSkills } from '../handlers/skillDataHandlers';
import ErrorMessage from './error-message';

const MainView = () => {
  const skillsUser = localStorage.getItem('skills-user');
  const parsedData = JSON.parse(skillsUser);
  const myData = useSelector(({ userdata }) => {
    return userdata.myData;
  });
  const myInfo = useSelector((state: any) => {
    return state.login.externalData?.profile;
  }) as CandidateInfo;

  const myProfileData: ProfileData = {
    skillTree: myData?.skillTree,
    externalId: myInfo?.id,
    externalType: ExternalType.Person,
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (!parsedData.profileId) return;
    const initSkills = async () => {
      const myInitialSkills = await getMyInitialSkills(
        parsedData.profileId
      );
      dispatch({
        type: 'userdata/addMyProfile',
        payload: myInitialSkills,
      });
    };
    initSkills();
    return () => {
      dispatch({
        type: 'search/setSkillLoader',
        payload: null,
      });
      dispatch({
        type: 'search/setErrorMessage',
        payload: null,
      });
    };

    // TODO don't run get initial skills if data is kept in redux store
  }, []);

  return (
    <div className="skills-view">
      <div className="skills-view-infos">
        <Instructions />
        <ErrorMessage />
      </div>
      <FillArea
        onMapChange={() => {}}
        profileData={myProfileData}
        serverId={parsedData?.profileId}
        shouldUpdateServer={true}
      />
    </div>
  );
};

export default MainView;
