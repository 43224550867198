import React from 'react';
import { List } from 'semantic-ui-react';
import { CandidateData, Candidate } from '../interfaces';
import MatchCalculatorWithDisplay from './match-calculator-with-display';

interface CandidatesListProps {
  candidateData: CandidateData[] | undefined;
  candidatesPool: Candidate[];
}

/**
 *
 * @param param0 candidateData contains person profiles only if the data exists, otherwise it will contain candidate profiles, which look like
 * @returns
 */
const CandidateDisplayList = ({
  candidateData,
  candidatesPool,
}: CandidatesListProps) => {
  let candidates = [];
  if (candidateData) {
    candidates = candidateData;
  } else {
    candidates = candidatesPool;
  }
  return (
    <div className="candidates-list">
      <List celled>
        {candidates.map((candidate) => {
          return (
            <List.Item key={candidate.externalId}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <List.Content style={{ flex: '1' }}>
                  {candidate.profileInfo ? (
                    <List.Header>
                      {candidate.profileInfo?.photo && (
                        <div>
                          <img
                            src={`${candidate.profileInfo.photo}`}
                          ></img>
                        </div>
                      )}
                      {candidate.profileInfo.name}
                    </List.Header>
                  ) : (
                    <p>{`${candidate.externalType}: ${candidate.externalId}`}</p>
                  )}
                  <p>{`Postcode: ${candidate.profileInfo?.postcode}`}</p>
                  <p>{`Available: ${candidate.profileInfo?.available}`}</p>
                </List.Content>
                <List.Content
                  style={{
                    flex: '1',
                    padding: '1.5rem',
                    margin: 'auto',
                    textAlign: 'center',
                  }}
                >
                  <MatchCalculatorWithDisplay candidate={candidate} />
                </List.Content>
              </div>
            </List.Item>
          );
        })}
      </List>
    </div>
  );
};

export default CandidateDisplayList;
