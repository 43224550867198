import React, { useState, useEffect } from 'react';
import { Map } from 'immutable';
import { Form } from 'semantic-ui-react';
import {
  Skill,
  ProfileData,
  ExternalType,
  SkillValueData,
  SkillValue,
} from '../interfaces';
import CategorySelectedIcons from './category-selected-icons';
import { sendSkillValueToServer } from '../handlers/skillValueHandlers';
import Subskill from './subskill';

import {
  sortSkillsByName,
  onSkillLevelChange,
  removeDefaultSkills,
  fromSkillTreetoSubskillMap,
} from '../helpers/skills';
import { useDispatch } from 'react-redux';

interface SkillCategoryProps {
  key: number;
  serverId: string;
  profileData: ProfileData;
  authToken: string;
  onMapChange: (
    map: Map<string, Map<string, SkillValueData>>
  ) => void;
  shouldUpdateServer: Boolean;
  editMode?: string;
}

const SkillCategory = (props: SkillCategoryProps) => {
  const [collapsed, setCollapsed] = useState<Boolean>(true);
  const [categoryTree, setCategoryTree] = useState<Skill>(
    props.profileData.skillTree
  );
  // capabilities should map unique skill ids to map of subskill capabilities
  // const [capabilities, setCapabilities] = useState<Map<string, Map<string, SkillValueData>>>(Map());
  const [subskills, setSubskills] =
    useState<Map<string, Map<string, SkillValueData>>>(Map());
  const [skillDataEntry, setSkillDataEntry] =
    useState<SkillValueData>(null);
  const [shouldChangeMap, setShouldChangeMap] =
    useState<Boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const subskillsMap = fromSkillTreetoSubskillMap(categoryTree);
    setSubskills(subskillsMap);
    return () => setSubskills(Map());
  }, [categoryTree]);

  useEffect(() => {
    if (shouldChangeMap) {
      const sequenceIter = removeDefaultSkills(subskills);
      props.onMapChange(sequenceIter);
    }
    if (
      shouldChangeMap &&
      shouldUpdateServer &&
      props.serverId &&
      skillDataEntry
    ) {
      sendSkillValueToServer(
        props.authToken,
        props.serverId,
        skillDataEntry,
        dispatch
      );
    }
  }, [subskills]);

  useEffect(() => {
    setShouldChangeMap(false);
    setCategoryTree(props.profileData.skillTree);
  }, [props.profileData]);

  const onSkillClicked = (
    event: React.SyntheticEvent<HTMLElement>
  ): void => {
    setCollapsed(!collapsed);
  };

  const { shouldUpdateServer } = props;
  const uniqueCategoryId = `${categoryTree.path}-${categoryTree.id}`;
  return (
    <Form className="category">
      <div className="skill-category" id={uniqueCategoryId}>
        <a className="category-caption" onClick={onSkillClicked}>
          <p className="skill-title">{categoryTree.name}</p>
          <CategorySelectedIcons selectionMap={subskills} />
        </a>

        {sortSkillsByName(categoryTree.children).map((subskill) => {
          const uniqueSubskillId = `${subskill.path}-${subskill.id}`;
          const initialSubskillValue =
            subskill.skillValue ?? SkillValue.Empty;
          const showSkillCapabilities =
            props.profileData.externalType === ExternalType.Person &&
            Number(subskills.getIn([subskill.name, 'skillValue'])) >
              Number(SkillValue.Amateur)
              ? true
              : false;

          return (
            <Subskill
              key={uniqueSubskillId}
              collapsed={collapsed}
              showSkillCapabilities={showSkillCapabilities}
              onSubskillValueChange={(
                skillReadableName,
                skillData,
                newValue //comes from subskill, the clicked value
              ) =>
                onSkillLevelChange(
                  subskills,
                  setSubskills,
                  setSkillDataEntry,
                  skillReadableName,
                  skillData,
                  newValue,
                  shouldUpdateServer,
                  setShouldChangeMap
                )
              }
              subskillData={{
                ...subskill,
                skillValue: initialSubskillValue,
              }}
              editMode={props.editMode}
            />
          );
        })}
      </div>
    </Form>
  );
};

export default SkillCategory;
