import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { Skill, SkillValue, SkillValueData } from '../interfaces';
import AddNewCapabilityButton from './add-new-capability';
import SkillSelection from './skill-selection';

interface SubskillProps {
  collapsed: Boolean;
  showSkillCapabilities: Boolean;
  onSubskillValueChange: (
    skillReadableName: string,
    skillData: SkillValueData,
    skillValue: SkillValue
  ) => void;
  subskillData: Skill;
  editMode?: string;
}

const Subskill = ({
  collapsed,
  showSkillCapabilities,
  onSubskillValueChange,
  subskillData,
  editMode,
}: SubskillProps) => {
  const subskillValueDataEntry: SkillValueData = {
    skillId: subskillData.id,
    skillPath: subskillData.path,
    skillValue: subskillData.skillValue,
  };
  showSkillCapabilities = false; // Hiding the capability button for now
  const [initialSkillValue, setInitialSkillValue] = useState(
    subskillData?.skillValue
  );
  useEffect(() => {
    setInitialSkillValue(subskillData.skillValue);
    return () => {
      setInitialSkillValue(SkillValue.Empty);
    };
  }, [subskillData]);

  return (
    <div className="subskill">
      <div
        className={`category-content-${
          collapsed ? 'collapsed' : 'noncollapsed'
        }`}
      >
        <Form.Field>
          <div className="skill">
            <div className="skill-name">{subskillData.name} </div>
            <SkillSelection
              id={subskillData.id}
              initialValue={initialSkillValue}
              onSkillValueChange={(newSkillValue) =>
                onSubskillValueChange(
                  subskillData.name,
                  subskillValueDataEntry,
                  newSkillValue
                )
              }
              editMode={editMode}
            />
          </div>
          {showSkillCapabilities ? (
            <AddNewCapabilityButton
              onClick={() => console.log(`Let's add a capability`)}
              onChange={(newCapabilityName) => {
                console.log(`Let's add ${newCapabilityName}`);
              }}
            />
          ) : null}
        </Form.Field>
      </div>
    </div>
  );
};

export default Subskill;
