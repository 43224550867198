import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { SkillValue } from '../interfaces';
import { useDispatch, useSelector } from 'react-redux';

interface SkillSelectionProps {
  id: string;
  initialValue: SkillValue;
  onChange: (level: SkillValue) => void;
  title: string;
  initialLevel: SkillValue;
  onSetSkillValue: Function;
  editMode?: string;
}

const SkillButton = ({
  id,
  initialValue,
  onChange,
  title,
  initialLevel,
  onSetSkillValue,
  editMode,
}: SkillSelectionProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const isUpdating = useSelector(({ search }) => {
    return search.skillLoader;
  });
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname && !pathname?.startsWith('/find-consultant')) {
      const found = isUpdating.find((skill) => skill.id === id);
      found &&
        found.loadingState &&
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
    }
  }, [isUpdating]);

  const getLevelClass = (level: number): string => {
    if (pathname && !pathname?.startsWith('/find-consultant')) {
      if (isLoading) {
        return level === initialValue ? `level-loading` : 'level--1';
      }
      const found = isUpdating.find((skill) => skill.id === id);
      if (found && found.loadingState === 'error') {
        let color =
          level === initialValue ? 'level-error' : 'level--1';
        return color;
      }
    }
    return level === initialValue ? `level-${level}` : 'level--1';
  };

  function skillOnClick(level: SkillValue) {
    if (pathname?.startsWith('/consultant') && !editMode) {
      return;
    }
    if (pathname && !pathname?.startsWith('/find-consultant')) {
      setIsLoading(true);
    }
    onSetSkillValue(level);
    onChange(level);
  }
  return (
    <Button
      loading={isLoading}
      className={`skill-level ${getLevelClass(initialLevel)}`}
      title={title}
      onClick={(event: SyntheticEvent) => skillOnClick(initialLevel)}
    ></Button>
  );
};
export default SkillButton;
