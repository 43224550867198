import React from 'react';
import { useSelector } from 'react-redux';
import { Segment, Header } from 'semantic-ui-react';

const ProfileView = () => {
  const googleUserLoginInfo = JSON.parse(
    localStorage.getItem('skills-user')
  );

  return (
    <div className="profile-view">
      <Segment>
        <div>
          <Header as="h2">Profile Information</Header>
          <span>{`Logged in user: ${googleUserLoginInfo?.name}`}</span>
          <Header as="h3">Email</Header>
          <span>{`Google account: ${googleUserLoginInfo?.email}`}</span>
        </div>
      </Segment>
    </div>
  );
};

export default ProfileView;
